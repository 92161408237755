import FeaturesSectionV2 from 'components/LSRevamp/ieltsPage/FeaturesSectionV2';
import IeltsHeroComponent from 'components/LSRevamp/ieltsPage/IeltsHeroComponent';
import { logEvent } from 'ga';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { navRoutes } from 'page_routes';
import { eventCategory, eventName } from 'DataMapper/EventTracker/EventData';
import { trackIeltsClick } from 'scripts/segment';
import { ieltsPageName } from 'utils/events-utils';
import { scrollToIeltsDemoForm } from 'utils/ielts_batch_utils';

type Props = {
  userDetailsFormRef?: any;
  heading?: string | null;
  description?: string | null;
  ctaBtnText?: string | null;
};

const HeroWrapper: FunctionComponent<Props> = ({
  userDetailsFormRef,
  heading,
  description,
  ctaBtnText,
}) => {
  const router = useRouter();
  return (
    <section>
      <IeltsHeroComponent
        title={
          <h1 className="font-bold text-[32px] md:text-[42px] leading-tight my-2">
            {heading || 'Crack IELTS with 7+ Bands in 4 Weeks'}
          </h1>
        }
        subtitle={
          description ? (
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          ) : (
            <ul>
              <li className="pb-4">
                Over 2,50,000+ IELTS aspirants got their{' '}
                <h2 className="inline-block">
                  dream IELTS Band with LeapScholar{' '}
                </h2>
              </li>
            </ul>
          )
        }
        webImg={
          'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/masterclass_hero.png'
        }
        imageDimensions={{
          mobileHeight: 251,
          mobileWidth: 328,
          webHeight: 418,
          webWidth: 536.77,
        }}
        background="linear-gradient(180deg, #F0F3FF 0%, #FFF2F3 100%)"
        ctaSection={
          <button
            onClick={() => {
              logEvent(
                eventCategory.IELTS_CRASH_COURSE_WEBSITE,
                eventName.START_LEARNING_FOLD1,
                '',
              );
              if (router.pathname === navRoutes.IELTS_FREE_CRASH_COURSE) {
                trackIeltsClick(
                  ieltsPageName.IELTS_FREE_CRASH_COURSE_LANDING_PAGE,
                  {
                    widgetName: 'Hero',
                    widgetFormat: 'Banner',
                    contentName: 'Start Free Trial',
                    contentFormat: 'Button',
                  },
                );
                logEvent('Ielts free trial', 'click', 'start free trial hero');
                document.getElementById('ielts_batches_list')?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center',
                });
              } else {
                trackIeltsClick(ieltsPageName.IELTS_CRASH_COURSE_PAGE, {
                  widgetName: 'Hero',
                  widgetFormat: 'Banner',
                  contentName: 'Book a Demo',
                  contentFormat: 'Button',
                });
                if (userDetailsFormRef) {
                  scrollToIeltsDemoForm(userDetailsFormRef);
                }
              }
            }}
            className="bg-yellow-to-orange cursor-pointer text-white px-12 py-2 font-bold text-[20px] rounded md:p-4 pb"
          >
            {ctaBtnText || 'Try Free MasterClass'}
          </button>
        }
        customLayoutCss="pb-28"
      />
      <div className="bottom-[80px] md:bottom-[100px] rounded-10 max-w-[320px] md:max-w-[1120px] bg-white m-auto relative z-2 shadow-1">
        <FeaturesSectionV2 />
      </div>
    </section>
  );
};
export default HeroWrapper;
