import { imageBasePath } from 'utils/imageUtils';

export interface FeatureInterFace {
  imgSrc: string;
  alt: string;
  title: string;
  id?: number;
}

export const FeatureItems: Array<FeatureInterFace> = [
  {
    id: 1,
    imgSrc: `${imageBasePath}/avataran_assets/images/ielts/featuresSection/students_trained_icon.svg`,
    alt: "Learn from India's top IELTS Trainers",
    title: "Learn from India's top IELTS Trainers",
  },
  {
    id: 2,
    imgSrc: `${imageBasePath}/avataran_assets/images/ielts/featuresSection/bands_Icon.svg`,
    alt: '90% score 7+ bands',
    title: '90% score 7+ bands',
  },
  {
    id: 3,
    imgSrc: `${imageBasePath}/avataran_assets/images/ielts/featuresSection/average_student_rating_icon.svg`,
    alt: 'Average student rating of 4.5/5',
    title: 'Average student rating of 4.5/5',
  },
  {
    id: 4,
    imgSrc: `${imageBasePath}/avataran_assets/images/ielts/featuresSection/pan_india_community.svg`,
    alt: 'PAN India community',
    title: 'PAN India community',
  },
];

export const AiEvoluationFutureDetail: Array<FeatureInterFace> = [
  {
    imgSrc: `${imageBasePath}/avataran_assets/images/ai-evolutiation/img_1.svg`,
    alt: 'Comprehensive Feedback',
    title: 'Comprehensive Feedback',
  },
  {
    imgSrc: `${imageBasePath}/avataran_assets/images/ai-evolutiation/img_2.svg`,
    alt: 'Latest IELTS Topics and Questions',
    title: 'Latest IELTS Topics and Questions',
  },
  {
    imgSrc: `${imageBasePath}/avataran_assets/images/ai-evolutiation/img_3.svg`,
    alt: 'Improvement in all 4 IELTS Parameters',
    title: 'Improvement in all 4 IELTS Parameters',
  },
  {
    imgSrc: `${imageBasePath}/avataran_assets/images/ai-evolutiation/img_4.svg`,
    alt: 'Brand 7+ Sample',
    title: 'Brand 7+ Sample',
  },
  {
    imgSrc: `${imageBasePath}/avataran_assets/images/ai-evolutiation/img_5.svg`,
    alt: 'Real IELTS Marking',
    title: 'Real IELTS Marking',
  },
  {
    imgSrc: `${imageBasePath}/avataran_assets/images/ai-evolutiation/img_6.svg`,
    alt: 'Review Your Recordings',
    title: 'Review Your Recordings',
  },
];

export const PlusFutureDetail: Array<string> = [
  'Your IELTS is 0-3 months away',
  'You are looking for strategies to score 7+ bands in a short amount of time',
  'Your English level is moderate to fluent',
];

export const FoundationFutureDetail: Array<string> = [
  'Your IELTS is 3-6 months away or more',
  'You are looking to learn the basics about IELTS',
  'Your English level is beginner to moderate',
];

export interface BenefitsArray {
  image: string;
  heading: string;
  subHeading?: string;
}
export const benefitsArray: Array<BenefitsArray> = [
  {
    image: `${imageBasePath}/avataran_assets/images/course/ielts_benefits/top_trainer.png`,
    heading: 'India’s Top IELTS Trainers',
    subHeading:
      'Learn from India’s Best IELTS Trainers. Get an exclusive mentor to guide you throughout the IELTS online preparation journey',
  },
  {
    image: `${imageBasePath}/avataran_assets/images/course/ielts_benefits/online_learning.png`,
    heading: 'Best live learning experience',
    subHeading:
      'Live IELTS online classes and 6 month access to class recordings',
  },
  {
    image: `${imageBasePath}/avataran_assets/images/course/ielts_benefits/daily_doubts.png`,
    heading: 'Daily Doubt Solving Sessions',
    subHeading: 'Additional doubt resolving sessions on a daily basis',
  },
  {
    image: `${imageBasePath}/avataran_assets/images/course/ielts_benefits/practice_test.png`,
    heading: 'Practice Tests',
    subHeading:
      'Get 100+ IELTS Mock Tests with Speaking and writing evaluations by experts',
  },
  {
    image: `${imageBasePath}/avataran_assets/images/course/ielts_benefits/time_management.png`,
    heading: 'Convenient and Flexible Timings',
    subHeading:
      'Select IELTS online classes that suit your schedule. Join weekday or weekend batches suitable to your timings',
  },
  {
    image: `${imageBasePath}/avataran_assets/images/course/ielts_benefits/user_location.png`,
    heading: 'Personalised Guidance',
    subHeading:
      'We ensure that everyone gets personal attention and guidance from the IELTS trainers',
  },
];
