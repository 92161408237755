import { FunctionComponent } from 'preact';
import {
  FeatureItems,
  FeatureInterFace,
} from 'DataMapper/IeltsSection/featueSection';
import Image from 'next/image';

interface FeatureCardProps {
  idx: number;
  imgSrc: string;
  alt: string;
  title: string;
  isBordered?: boolean;
}

const FeatureSection = () => {
  return (
    <div
      style={{ background: 'linear-gradient(90deg, #FBD87A 0%, #EC821B 100%' }}
      className="py-8 md:py-12 px-4"
    >
      <div className="max-w-[1150px] mx-auto bg-white shadow-2 rounded">
        <div className="grid grid-cols-2 md:grid-cols-4">
          {FeatureItems.map((feature: FeatureInterFace, index: number) => (
            <div key={index} className="py-4 md:py-8">
              <FeatureCard
                idx={index}
                imgSrc={feature.imgSrc}
                alt={feature.alt}
                title={feature.title}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const FeatureCard: FunctionComponent<FeatureCardProps> = ({
  imgSrc,
  alt,
  title,
  idx,
  isBordered = true,
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-center border-[#E8E8E8] px-4 ${
        isBordered
          ? idx !== 0 && idx !== 4
            ? 'border-r border-l '
            : idx === 0
            ? 'border-r'
            : 'border-l'
          : ''
      }`}
    >
      <Image
        src={imgSrc}
        alt={alt}
        width="70"
        height="70"
        className="md:w-24 pb-4"
        loading="eager"
      />
      <div className="text-center font-bold text-[#0A0926] md:text-[18px] md:px-4">
        {title}
      </div>
    </div>
  );
};

export default FeatureSection;
