import { FaqSchema } from 'DataMapper/NewLandingPageData/Homepage';
import Link from 'next/link';

export const masterclassFaqData: Array<FaqSchema> = [
  {
    id: '1',
    header: 'What is a Masterclass?',
    content: `A Masterclass is a series of free live IELTS classes that we at LeapScholar conduct. Our Masterclasses cover all 4 sections of IELTS and are taught by India’s leading IELTS educators. Keep checking this page for the latest Masterclass schedule!`,
  },
  {
    id: '2',
    header: 'How can I attend the Masterclass?',
    content: `Simply click on ‘Reserve Free Seat’ and answer a few basic questions about yourself. You will be added to the classes. You can choose to be reminded for certain classes that you find most interesting. You will receive the meeting details on your phone and email.`,
  },
  {
    id: '3',
    header: 'How long is the Masterclass?',
    content: `It is a 1 hour class.`,
  },
  {
    id: '4',
    header: 'Will recordings be available of the Masterclasses?',
    content: `Unfortunately no. You will have to attend the classes to gain the benefit. If you are busy today, don’t worry - we conduct classes from Monday-Saturday.`,
  },
  {
    id: '5',
    header: 'I am just a beginner in IELTS. Is this Masterclass for me?',
    content: `We start from the beginning in every Masterclass. No matter what stage of IELTS preparation you are in, we assure you that you will find the class useful.`,
  },
  {
    id: '6',
    header: 'What is the difference between a Masterclass and IELTS Plus?',
    content: `The Masterclasses consist of free live classes only. We will not be able to cover all topics for IELTS. Moreover, you will not get any study material, writing evaluations or speaking evaluations. If you want all of the above and more, check out our IELTS Plus.`,
  },
  {
    id: '7',
    header: 'How many masterclasses can I attend?',
    content: `You can attend as many masterclasses as you want! You can join in using the same link every time.`,
  },
  {
    id: '8',
    header: 'What happens after I book a free masterclass?',
    content: `After booking your class, you will be redirected to your Student Dashboard. Here you will find the links to join your classes, a notice board to receive messages, a telegram group link to connect with your batchmates, and much more!`,
  },
  {
    id: '9',
    header:
      'I have some more questions regarding IELTS Plus. How can I get them resolved?',
    content: `If you have any other queries or issues you can email us at contact@leapfinance.com`,
  },
];

export const ieltsFaq: Array<FaqSchema> = [
  {
    id: '1',
    header: 'Why choose LeapScholar’s IELTS Plus coaching classes?',
    content: `The classes provide access to a wide range of IELTS study resources at affordable prices.`,
  },
  {
    id: '2',
    header: 'I am a beginner at IELTS. Is this course for me?',
    content: `We will cover all topics from basics to advanced in the IELTS Plus. If you are struggling with English grammar, we also provide extra classes for your grammar.`,
  },
  {
    id: '3',
    header: 'How many mock tests will I get, and what is included in those?',
    content: `There’ll be 5 mock tests included in IELTS Plus. The mock test will have all 3 sections, i.e. Writing, Reading & Listening. The instructor will unlock the mock tests as the course progresses. As a part of the test series, you get an expert evaluation for 3 essays. We will let you know precisely where you need to improve to give your best performance.`,
  },
  {
    id: '4',
    header: 'What is the duration of the IELTS Plus course?',
    content: `There are two options. You can choose either a 4-week course or a 2-week course.`,
  },
  {
    id: '5',
    header: 'Will I get the recorded videos if I miss the live class?',
    content: `Yes, you can re-watch recordings of all the live sessions. You'll have lifetime access to the recordings.`,
  },
];

export const crashCourseChallengeIeltsFaq: Array<FaqSchema> = [
  {
    id: '1',
    header: 'Why choose LeapScholar’s IELTS classes?',
    content: `We at Leap Scholar provide quality IELTS classes by expert educators. We have trained over 250,000 students to achieve their dream band.`,
  },
  {
    id: '2',
    header: 'I am a beginner at IELTS. Is this challenge for me?',
    content: `We will cover all topics from basics to advanced in the IELTS Plus. If you are struggling with English grammar, we also provide extra classes for your grammar.`,
  },
  {
    id: '3',
    header: 'How many mock tests will I get, and what is included in those?',
    content: `Along with 10 hours of live classes, you get access to 2 Reading, 2 Listening, 1 Writing mocks & 1 Speaking Evaluation. As you participate in the challenge, more mocks can be unlocked for free as rewards.`,
  },
  {
    id: '4',
    header: 'Is there any fee associated with the IELTS Plus challenge?',
    content: `IELTS Plus challenges are free one time per user. With the IELTS Plus challenge, you get 10 hours of live classes and a few mock tests. If you need more classes, mock tests, you can check our paid challenges: IELTS Premium`,
  },
  {
    id: '5',
    header: 'Will I get the recorded videos if I miss the live class?',
    content: `Yes, you can re-watch recordings of all the live sessions. You'll have lifetime access to the recordings.`,
  },
];

export const aiEvalutionFaq: Array<FaqSchema> = [
  {
    id: '1',
    header: 'How long does SmartTests take to get my results?',
    content: `You’ll get your results immediately after taking the speaking/writing test!`,
  },
  {
    id: '2',
    header: 'Who checks my test submissions?',
    content: `The evaluation is done using artificial intelligence. SmartTests has been trained using thousands of IELTS results, and hence is extremely accurate in predicting your IELTS Score.
    `,
  },
  {
    id: '3',
    header: ' Will I get a report on my mistakes?',
    content: `Yes! SmartTests’ comprehensive feedback tells you not just the areas you can improve upon, but also marks you on actual IELTS parameters thus helping you get the best IELTS score.`,
  },
  {
    id: '4',
    header: 'Is there a free demo?',
    content: `Yes, you can take a mini speaking/writing test. These demo tests are shorter in duration and smaller in their evaluation scope than the paid SmartTests.`,
  },
];

export interface FaqSchemaText {
  id: string;
  header: string;
  content: string;
  link?: string;
}
export const ieltsAccordionData: FaqSchemaText[] = [
  {
    id: '1',
    header: 'What are the details of one on one IELTS online courses?',
    content: `Our one-on-one IELTS online courses are customizable as per your exam preparation schedule. For more information, use the request a callback option. We will get back to you.`,
  },
  {
    id: '2',
    header: 'Will all IELTS programs have live doubt-solving sessions?',
    content: `Yes. Apart from class interaction in your class, you can attend daily doubt-solving sessions during the course.`,
  },
  {
    id: '3',
    header: 'What are the IELTS online class timings?',
    content:
      'The class timings for all batches will have options. You can choose the batch which best suits your schedule.',
  },
  {
    id: '4',
    header: 'Are these IELTS online courses for Academic or General students?',
    content: `We have designed these courses for both IELTS Academic and General students.`,
  },
  {
    id: '5',
    header: 'What is the difference among all the IELTS program batches?',
    content:
      'Number of students and evaluations differ based on course selection.',
  },
  {
    id: '6',
    header: 'Are the IELTS online preparation programs free of cost?',
    content:
      'Only the first demo class will be free. After that a nominal fee will be charged based on which IELTS Program you choose.',
  },
  {
    id: '7',
    header: 'Will all IELTS programs have live doubt-solving sessions?',
    content:
      'Yes. Apart from class interaction in your class, you get the option to attend daily doubt-solving classes in all the programmes.',
  },
  {
    id: '8',
    header: 'What is the duration of the IELTS Program?',
    content:
      'The IELTS program will offer live classes with an Expert Trainer, 100+ Reading & Listening scored mock tests, full length speaking evaluations and generous writing evaluations for complete practice. IELTS Elite course students get access to a Student Mentor.',
  },
];

export const ieltsAccordionDataWithLinks: FaqSchema[] = [
  {
    id: '1',
    header: 'What are the different batches available for IELTS preparation?',
    content: (
      <p>
        You can choose from 3 batches: IELTS Focus, IELTS Prime and IELTS Boost
      </p>
    ),
  },
  {
    id: '2',
    header: 'Are these courses for IELTS Academic or General students?',
    content: (
      <p>
        Our IELTS courses are suitable for both Academic test takers and General
        test takers.
      </p>
    ),
  },
  {
    id: '3',
    header: 'Are the programmes free of charge?',
    content: (
      <p>
        The first class from our IELTS course is free. After that a fee will be
        charged based on the IELTS program you choose.
      </p>
    ),
  },
  {
    id: '4',
    header: 'What is the difference among all the batches?',
    content: (
      <p>
        The number of learning hours and tests varies based on the IELTS program
        you choose. IELTS Boost is a self-prep course where you can prepare at
        your own pace.
      </p>
    ),
  },
  {
    id: '5',
    header: 'What is the duration of Program?',
    content: (
      <p>
        IELTS Focus has 20 hours of live classes while IELTS Prime has 30 hours
        of live classes. Both these programs have daily free grammar and doubt
        solving classes that you can join.
      </p>
    ),
  },
  {
    id: '6',
    header: 'What are the class timings?',
    content: (
      <p>
        We have multiple batches at various timings, you can choose a batch that
        fits your schedule.
      </p>
    ),
  },
  {
    id: '7',
    header: 'Will all programmes have live doubt solving sessions?',
    content: (
      <p>
        Yes, all our programmes have dedicated doubt-solving sessions held
        daily.
      </p>
    ),
  },
];

export const courses = [
  'IELTS Elite Course',
  'IELTS Select Course',
  'Crash course/Grammar or a Sectional course',
];

export type FaqItem = {
  id: number;
  key: string | null;
  value: string | null;
};

export type IELTSPreparationData = {
  id: number;
  heading: string;
  description: string;
  ctaBtnText: string;
  page_name: string;
  published_at: string;
  created_at: string;
  updated_at: string;
  contentfaq: FaqItem[];
  faqHeading: string;
  faqCtaText: string;
  faqList: FaqItem[];
  meta_data: {
    id: number;
    title: string;
    description: string;
    keywords: string | null;
    canonical: string | null;
  };
};

export function extractTextFromHTML(html: string) {
  try {
    // Replace &nbsp; with regular spaces
    html = html.replace(/&nbsp;/g, ' ');
    // Remove HTML tags
    const regex = /(<([^>]+)>)/gi;
    const plainText = html.replace(regex, '');

    return plainText;
  } catch (e) {
    return html;
  }
}
