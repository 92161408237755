import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import ExpertCard from 'components/LSRevamp/common/ExpertCard';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'Interfaces/storeSchema';
import { facultyActions } from 'components/Ielts/Faculty/redux/FacultyActions';
import { TeacherSchema } from 'Interfaces/reducerTypes/TeacherSchema';

interface Props {
  title?: string | ReactNode;
  cardsData?: any;
  isMobileSized?: boolean;
  severFacultyData?: Array<TeacherSchema>;
}

const CounsellorInfoWrapper: FunctionComponent<Props> = ({
  cardsData = [],
  title,
  severFacultyData,
}) => {
  const [showMore, setShowMore] = useState(false);
  const facultyData = useSelector(
    (state: State) => state.ieltsFaculty.facultyData,
  );
  const [faculty, setFaculty] = useState<Array<TeacherSchema>>([]);

  useEffect(() => {
    if (severFacultyData) setFaculty([...severFacultyData].reverse());
    else if (facultyData) setFaculty([...facultyData].reverse());
  }, [facultyData]);

  return (
    <div className="md:px-12 px-4">
      {title ? (
        title
      ) : (
        <h2 className="font-bold text-[24px] md:text-[40px] text-center my-8">
          Best Educators in India
        </h2>
      )}
      <div className="md:grid md:grid-cols-3 gap-6 flex overflow-x-auto place-items-center">
        {faculty?.length &&
          faculty?.map((facultyItem: any, idx: number) => {
            if (!showMore && idx > 5) return;
            return (
              <ExpertCard
                key={facultyItem?.id}
                image={facultyItem?.image}
                linkedinAddress={facultyItem?.linkedinUrl}
                personName={facultyItem?.name}
                yearsOfExperience={facultyItem?.yearsOfExperience}
                expertise={facultyItem?.expertise}
                content={
                  <div>
                    <p>{facultyItem?.description}</p>
                    <p>{facultyItem?.shortDescription}</p>
                  </div>
                }
                withExpertise={false}
                ieltsCard={false}
                widthClasses="min-w-[313px] md:w-[313px]"
              />
            );
          })}
      </div>
      <div className="md:flex justify-center items-center my-8 md:mt-16 hidden ">
        {!showMore && (
          <button
            onClick={() => setShowMore(true)}
            className="border border-2 border-primary cursor-pointer text-primary px-6 py-4 rounded w-full md:w-56 font-bold text-[16px]"
          >
            Show More
          </button>
        )}
      </div>
    </div>
  );
};

export default CounsellorInfoWrapper;
