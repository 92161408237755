import Image from 'next/image';
import React, { FunctionComponent, ReactNode } from 'react';
import useDevice from 'hooks/useDevice';

interface Props {
  webImg?: string;
  background?: string;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  ctaSection?: string | ReactNode;
  imageDimensions: {
    mobileWidth: number;
    mobileHeight: number;
    webHeight: number;
    webWidth: number;
  };
  customLayoutCss?: string;
}

const IeltsHeroComponent: FunctionComponent<Props> = ({
  webImg,
  title,
  subtitle,
  background = '',
  ctaSection,
  imageDimensions,
  customLayoutCss,
}) => {
  const { isMobile } = useDevice();
  return (
    <div className={`md:px-32 px-4  ${customLayoutCss}`} style={{ background }}>
      <div className="grid md:grid-cols-2">
        <div className="flex flex-col justify-center gap-y-6 text-center md:text-left">
          {title ? title : null}
          {subtitle && (
            <div className="text-[16px] md:text-[20px] text-[#3A3A3A] leading-8">
              {subtitle}
            </div>
          )}
        </div>

        <div className="md:row-span-2 relative mt-6 md:my-6">
          <Image
            src={webImg || ''}
            alt={'hero_image'}
            layout="responsive"
            width={
              isMobile ? imageDimensions.mobileWidth : imageDimensions.webWidth
            }
            height={
              isMobile
                ? imageDimensions.mobileHeight
                : imageDimensions.webHeight
            }
            priority
            style={{
              objectFit: 'contain',
            }}
            loading="eager"
          />
        </div>
        {ctaSection && (
          <div className="my-4">
            <div className="flex items-center justify-center md:block">
              {ctaSection}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IeltsHeroComponent;
