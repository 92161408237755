import { FeatureCard } from 'components/Ielts/FeatureSection';
import {
  FeatureInterFace,
  FeatureItems,
} from 'DataMapper/IeltsSection/featueSection';
import { FunctionComponent } from 'react';

type Props = {};

const FeaturesSectionV2: FunctionComponent<Props> = () => {
  return (
    <div className="flex flex-col py-10 gap-4 rounded-2xl">
      <h2 className="font-bold text-lg md:text-[32px] text-center md:px-4">
        Why choose Leap Scholar?
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-4">
        {FeatureItems.map((feature: FeatureInterFace, index: number) => (
          <div key={feature.id} className="py-4 md:py-8">
            <FeatureCard
              idx={index}
              imgSrc={feature.imgSrc}
              alt={feature.alt}
              title={feature.title}
              isBordered={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesSectionV2;
